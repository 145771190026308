import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../common/Button"
import H3 from "../typography/H3"
import P from "../typography/P"
import BaseCard from "./BaseCard"

const FeatureCard = ({
  link,
  topic,
  title,
  text,
  image,
  button,
  date,
  mainText,
  noHover,
}) => {
  let wordcount
  let readingTimeMin
  if (mainText) {
    wordcount = mainText.split(" ").length
    readingTimeMin = Math.ceil(wordcount / 200)
  }

  const dateObject = new Date(date * 1000)
  var day = ("0" + dateObject.getDate()).slice(-2)
  var month = ("0" + (dateObject.getMonth() + 1)).slice(-2)
  var year = dateObject.getFullYear()
  return (
    <BaseCard noHover>
      <Link to={link}>
        <GatsbyImage image={getImage(image)} alt={title} className="mb-3" />
      </Link>
      <div className="flex flex-col justify-between">
        <div className="flex-1">
          {topic && (
            <p className="text-sm font-medium text-gray-700">{topic}</p>
          )}
          <Link to={link} className="block mt-2">
            <H3 className="mb-5">{title}</H3>
            <P>{text}</P>
            <div className="flex flex-col mt-3 space-x-1 text-sm text-gray-500 md:flex-row">
              <time dateTime={`${year}-${month}-${day}`}>
                {dateObject.toLocaleDateString("de-DE", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </time>
              {wordcount && readingTimeMin && (
                <>
                  <span aria-hidden="true" className="hidden md:inline">
                    ·
                  </span>
                  <span>
                    {wordcount} Wörter - Lesezeit: ca. {readingTimeMin} Minuten
                  </span>
                </>
              )}
            </div>
          </Link>
          {button ? <Button text={button} to={link} className="mt-3" /> : ""}
        </div>
      </div>
    </BaseCard>
  )
}

export default FeatureCard
