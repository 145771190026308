import React from "react"
const H3 = ({ children, display, className }) => {
  return (
    <h3
      className={`text-lg font-extrabold leading-relaxed sm:text-xl mmd:text-2xl ${
        display
          ? "text-2xl sm:text-3xl md:text-4xl"
          : "text-lg sm:text-xl md:text-2xl"
      } ${className}`}
    >
      {children}
    </h3>
  )
}

export default H3
