import React from "react"

const BaseCard = ({ children, noHover }) => {
  return (
    <div className={`p-6 rounded-md bg-white shadow-lg ${noHover ? "" : "transition-colors duration-300 hover:bg-brand-pink-dark hover:text-white"}`}>
      {children}
    </div>
  )
}

export default BaseCard
