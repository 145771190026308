import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"

const SchemaBreadcrumb = ({ pages }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [],
  }

  pages.forEach((page, i) => {
    const schemaData = {
      "@type": "ListItem",
      position: i + 1,
      name: page.name,
      item: `${data.site.siteMetadata.siteUrl}${page.to}`,
    }
    ldJson.itemListElement.push(schemaData)
  })

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </Helmet>
  )
}

export default SchemaBreadcrumb
