import { HomeIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import React from "react"
import SchemaBreadcrumb from "../layouts/schemas/SchemaBreadcrumb"

function Breadcrumb({ pages, className }) {
  return (
    <>
      <SchemaBreadcrumb pages={pages} />
      <nav
        className={`flex justify-center ${className ? className : ""}`}
        aria-label="Breadcrumb"
      >
        <ol className="flex px-6 space-x-4 bg-white rounded-md shadow">
          <li className="flex">
            <div className="flex items-center">
              <Link to="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="flex-shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
                <span className="sr-only">Startseite</span>
              </Link>
            </div>
          </li>
          {pages.map(page => (
            <li key={page.name} className="flex">
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-6 h-full text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </>
  )
}

export default Breadcrumb
