import { graphql } from "gatsby"
import React from "react"
import BlogCard from "../components/cards/BlogCard"
import Breadcrumb from "../components/common/Breadcrumb"
import Container from "../components/common/Container"
import GridThree from "../components/common/GridThree"
import Layout from "../components/layouts/Default"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Blog | Seo Agentur Stuttgart" desc="">
      <div className="bg-gray-50">
        <Container noPadding className="py-8 md:py-16">
          <Breadcrumb
            pages={[{ name: "Blog", href: "/blog/", current: true }]}
            className="mb-5"
          />
          <GridThree itemsStart>
            {data.allBlog.nodes.map((entry, i) => {
              return (
                <BlogCard
                  noHover
                  link={`/blog/${entry.title_slug}/`}
                  title={entry.title}
                  text={entry.excerpt}
                  image={entry.remoteImage}
                  button={"Weiterlesen"}
                  date={entry._created}
                  mainText={entry.content}
                  key={i}
                />
              )
            })}
          </GridThree>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allBlog(
      filter: { published: { eq: true } }
      sort: { fields: _created, order: DESC }
    ) {
      nodes {
        remoteImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        title_slug
        published
        excerpt
        content
        _created
      }
    }
  }
`
