import React from "react"

const Container = ({ children, noPadding, className, id }) => {
  return (
    <div
      className={`mx-auto max-w-screen-xl px-4 ${
        noPadding ? "" : "my-16 md:my-32 lg:my-64"
      } ${className ? className : ""}`}
      id={id}
    >
      <div>{children}</div>
    </div>
  )
}

export default Container
